<template>
  <div
    class="min-vh-100 d-flex flex-row align-items-center login-form-st"
    style="background-color: #111820;"
  >
    <CContainer style="min-height: 680px;margin-top:-180px">
      <CRow class="justify-content-center login-form">
        <CCol :md="8" :lg="5">
          <CCardGroup>
            <CCard style="padding-top: 44px; padding-bottom: 64px;max-width:380px;margin:auto">
              <CCardBody>
                <h3 style="color: #000; font-weight: 500; margin-bottom: 24px">
                  Login
                </h3>
                <img
                  src="@/assets/images/Hyphen-dark.png"
                  style="width: 180px; margin-bottom: 18px"
                  alt=""
                  srcset=""
                />

    
                  <div class="form-group" :class="{ error: v$.data.username.$errors.length }">
                    <input
                      class="form-control"
                      placeholder="Username here!"
                      type="text"
                      v-model="v$.data.username.$model"
                    />
                    <div v-if="v$.data.username.$errors.length" class="error-msg" style="margin-top: -5px;">Username is required!</div>
                  </div>
                    <div class="form-group" :class="{ error: v$.data.password.$errors.length }">
                    <input
                      class="form-control"
                      placeholder="Password here!"
                      type="password"
                      v-model="v$.data.password.$model"
                    />

                    <div v-if="v$.data.password.$errors.length" class="error-msg">Password is required!</div>
                  </div>

                  <div v-if="error" style="color:red;margin-top:-12px;margin-bottom:28px;font-size:14px">
                     {{error}}
                  </div>
        

                <div class="sign-in-btn">
                  <CButton
                    color="primary"
                    style="color: #c8a791"
                    class="px-4 mt-2"
                    @click="login"
                  >
                    <span v-if="loginInit">Authenticating...</span> <span v-else>Login</span> 
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
          <img
            src="@/assets/images/plentywhitetext.png"
            style="width: 94px; margin-top: 34px"
            alt=""
            srcset=""
          />
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {adminLogin} from "@/services/apis/auth"

export default {
  name: "Login",
  data() {
    return {
        data: {
          username: "",
          password: "",

      },
      loginInit:false,
      error:null,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  methods: {
    login() {
      this.loginInit = true;
      this.error = null;
        this.v$.$touch();
      if (this.v$.$invalid) {
        console.log("Form is invalid!");
      } else {
        adminLogin(this.data).then(res=>{
          localStorage.setItem('token', JSON.stringify(res.data.access))
          this.$router.push({name:'Subscription'})
        }).catch((err)=>{
          this.error = err.response.data.detail

        }).finally(()=>{
          this.loginInit = false;
        })
      }
    },
  },

  validations() {
    return {
      data: {
        username: {
          required,
        },
        password: {
          required,
        },
       
      },
    };
  },
};
</script>

<style scoped>
.sign-in-btn {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.login-form {
  text-align: center;
  padding-top: 100px;
  margin:auto;
}
.login-form h5 {
  margin-bottom: 32px;
}
.form-control {
  height: 48px;
  border: none;
  box-shadow: 0px 3px 6px 0px rgb(185 178 178 / 75%);
  -webkit-box-shadow: 0px 3px 6px 0px rgb(185 178 178 / 75%);
  -moz-box-shadow: 0px 3px 6px 0px rgb(185 178 178 / 75%);
}
.card {
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 75%);
}
.error-msg{
  font-size: 14px;
  color: red;
  text-align: left;
  padding-left: 8px;
  margin-top: 4px;
}
.form-group{
  margin-bottom: 28px;
}
</style>